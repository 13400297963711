import React from 'react'
import { graphql } from 'gatsby'
import { Row, Col } from 'react-flexbox-grid'

import Page from '../components/Page'
import Container from '../components/Container'
import Section from '../components/Section'
import Card from '../components/Card'

const InsightsPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => (
  <Page
    title='Insights'
    background='images/pages/Insights.jpg'
  >
    <Container>
      <Section>
        <Row>
          { edges.map((p, i) => (
            <Col md={4} sm={12} xs={12} key={i}>
              <Card
                title={p.node.frontmatter.title}
                date={p.node.frontmatter.date}
                description={p.node.frontmatter.teaser}
                path={p.node.frontmatter.path}
              />
            </Col>
          )) }
        </Row>
      </Section>
    </Container>
  </Page>
)

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            path
            title
            teaser
          }
        }
      }
    }
  }
`


export default InsightsPage
